import React from 'react'
import renderBloks from '@renderBloks'
import SbEditable from 'storyblok-react'
import { makeStyles } from '@material-ui/styles'
import { Box } from '@material-ui/core'
import { MarkdownHandler } from '@system'
import font from '@helpers/font'
import useStringTranslation from '@hooks/use-string-translation'
const { makeResponsiveFontSizes } = font

const h3FontSizes = makeResponsiveFontSizes(29)
const bodyFontSizes = makeResponsiveFontSizes(24, 11)

const useStyles = makeStyles((theme) => ({
  roadmapBlockRoot: {
    borderTop: `2px solid ${theme.palette.background.lightSlate}`,
    display: 'flex',
    width: 'calc(100% - 2.5rem)',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: '1.5rem',
    margin: '0 1.25rem 1.25rem',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  left: {
    width: '35%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  right: {
    width: '61%',
    [theme.breakpoints.down('sm')]: {
      borderTop: `2px solid ${theme.palette.background.lightSlate}`,
      paddingTop: '2rem',
      marginTop: '1.75rem',
      width: '100%',
    },
  },
  description: {
    color: theme.palette.text.primary,
    ...bodyFontSizes,
  },
  relatedProductsContainer: {},
  rpHeader: {
    padding: '1.5rem 0 .5rem',
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightBold,
    ...bodyFontSizes,
  },
  logosContainer: {
    '& > div': {
      marginBottom: '.25rem',
    },
    '& img': {
      objectPosition: 'left',
    },
  },
  featureDetail: {
    '& h3': {
      color: theme.palette.text.iris,
      fontWeight: theme.typography.fontWeightBold,
      marginTop: '0',
      ...h3FontSizes,
    },
    '& h4': {
      marginBottom: '0.25rem',
      color: theme.palette.text.primary,
      fontWeight: theme.typography.fontWeightBold,
      ...bodyFontSizes,
    },
    '& p': {
      ...bodyFontSizes,
    },
  },
}))

const RoadmapBlock = ({ blok }) => {
  const { description, relatedProducts, featureDetail } = blok
  const classes = useStyles()
  const rpString = useStringTranslation('Related Products:')

  return (
    <SbEditable content={blok}>
      <Box className={classes.roadmapBlockRoot}>
        <Box className={classes.left}>
          <MarkdownHandler className={classes.description}>
            {description}
          </MarkdownHandler>
          {relatedProducts.length > 0 && (
            <Box className={classes.relatedProductsContainer}>
              <Box className={classes.rpHeader}>{rpString}</Box>
              <Box className={classes.logosContainer}>
                {renderBloks(relatedProducts)}
              </Box>
            </Box>
          )}
        </Box>
        <Box className={classes.right}>
          <Box className={classes.featureDetail}>
            <MarkdownHandler>{featureDetail}</MarkdownHandler>
          </Box>
        </Box>
      </Box>
    </SbEditable>
  )
}

export default RoadmapBlock
